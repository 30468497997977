<!-- Page Content -->
<!-- Banner Starts Here -->
<!-- <div class="banner header-text">
      <div class="owl-banner owl-carousel">
        <div class="banner-item-01">
          <div class="text-content">
            <h4>Best Offer</h4>
            <h2>New Arrivals On Sale</h2>
          </div>
        </div>
        <div class="banner-item-02">
          <div class="text-content">
            <h4>Flash Deals</h4>
            <h2>Get your best products</h2>
          </div>
        </div>
        <div class="banner-item-03">
          <div class="text-content">
            <h4>Last Minute</h4>
            <h2>Grab last minute deals</h2>
          </div>
        </div>
      </div>
    </div> -->
<!-- Banner Ends Here -->

<!-- <ngb-carousel *ngIf="images" class="header-text">
      <ng-template ngbSlide>
        <div class="picsum-img-wrapper">
          <img [src]="images[0]" alt="Random first slide">
        </div>
        <div class="carousel-caption">
          <h4>Best Offer</h4>
            <h2>New Arrivals On Sale</h2>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </div>
      </ng-template>
      <ng-template ngbSlide>
        <div class="picsum-img-wrapper">
          <img [src]="images[1]" alt="Random second slide">
        </div>
        <div class="carousel-caption">
          <h4>Flash Deals</h4>
            <h2>Get your best products</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </div>
      </ng-template>
      <ng-template ngbSlide>
        <div class="picsum-img-wrapper">
          <img [src]="images[2]" alt="Random third slide">
        </div>
        <div class="carousel-caption">
          <h4>Last Minute</h4>
            <h2>Grab last minute deals</h2>
          <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
        </div>
      </ng-template>
    </ngb-carousel> -->

<div *ngIf="images" id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
  <ol class="carousel-indicators">
    <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
    <!-- <li data-target="#carouselExampleIndicators" data-slide-to="2"></li> -->
  </ol>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img class="d-block w-100 car-img" [src]="images[0]" alt="First slide">
      <div class="carousel-caption d-none d-md-block">
      </div>
    </div>
    <div class="carousel-item">
      <img class="d-block w-100 car-img" [src]="images[1]" alt="Second slide">
      <div class="carousel-caption d-none d-md-block">
      </div>
    </div>
    <div class="carousel-item">
      <img class="d-block w-100 car-img" [src]="images[2]" alt="Second slide">
      <div class="carousel-caption d-none d-md-block">
      </div>
    </div>
    <div class="carousel-item">
      <img class="d-block w-100 car-img" [src]="images[3]" alt="Second slide">
      <div class="carousel-caption d-none d-md-block">
      </div>
    </div>
    <!-- <div class="carousel-item">
          <img class="d-block w-100" style="height: 275px;" [src]="images[2]" alt="Third slide">
          <div class="carousel-caption d-none d-md-block">
          </div>
        </div> -->
  </div>
  <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>
<!-- <div id="carouselExampleIndicators" class="carousel slide header-text" data-ride="carousel">
      <ol class="carousel-indicators">
        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
      </ol>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img class="d-block w-100" src="../../assets/images/slide_01.jpg" alt="First slide">
          <div class="carousel-caption d-flex h-100 align-items-center justify-content-center">
            <div>
              <h5>Best Offer</h5>
              <p>New Arrivals On Sale</p>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <img class="d-block w-100" src="../../assets/images/slide_02.jpg" alt="Second slide">
          <div class="carousel-caption d-flex h-100 align-items-center justify-content-center">
            <div>
            <h5>Flash Deals</h5><br>
            <p>Get your best products</p>
          </div>
          </div>
        </div>
        <div class="carousel-item">
          <img class="d-block w-100" src="../../assets/images/slide_03.jpg" alt="Third slide">
          <div class="carousel-caption d-flex h-100 align-items-center justify-content-center">
            <div>
            <h5>Last Minute</h5>
            <p>Grab last minute deals</p>
          </div>
          </div>
        </div>
      </div>
      <a class="carousel-control-prev hidden" href="#carouselExampleIndicators" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next hidden" href="#carouselExampleIndicators" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div> -->

<div class="latest-products">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="section-heading">
          <h2>Latest Products</h2>
          <a routerLink="/products">view all products <i class="fa fa-angle-right"></i></a>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 all des dev gra" *ngFor="let product of products">
        <div class="product-item" style="box-shadow: 5px 10px 5px #FFF3F8;" (click)="setData(product)">
          <a><img class="border-bottom" src='{{ "assets/images/Products/" + product["420Path"][0] }}' alt=""></a>
          <div class="down-content">
            <div class="row">
              <div class="col-md-8">
                <p class="h5 m-0">{{product.productName}}</p>
              </div>
              <div class="col-md-4">
                <p class="h6" style="color: black; font-weight: bold;">&#8377; {{product.price.toFixed(2)}}</p>
              </div>
            </div>
            <!-- <p>{{product.shortDescription}}</p> -->
            <!-- <ul class="stars">
                    <li><i class="fa fa-star"></i></li>
                    <li><i class="fa fa-star"></i></li>
                    <li><i class="fa fa-star"></i></li>
                    <li><i class="fa fa-star"></i></li>
                    <li><i class="fa fa-star"></i></li>
                  </ul>
                  <span>Reviews ({{product.reviews}})</span> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="best-features">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="section-heading">
              <h2>About Sixteen Clothing</h2>
            </div>
          </div>
          <div class="col-md-6">
            <div class="left-content">
              <h4>Looking for the best products?</h4>
              <p><a rel="nofollow" href="#" target="_parent">This template</a> is free to use for your business websites. However, you have no permission to redistribute the downloadable ZIP file on any template collection website. <a rel="nofollow" href="https://templatemo.com/contact">Contact us</a> for more info.</p>
              <ul class="featured-list">
                <li><a href="#">Lorem ipsum dolor sit amet</a></li>
                <li><a href="#">Consectetur an adipisicing elit</a></li>
                <li><a href="#">It aquecorporis nulla aspernatur</a></li>
                <li><a href="#">Corporis, omnis doloremque</a></li>
                <li><a href="#">Non cum id reprehenderit</a></li>
              </ul>
              <a routerLink="about-us" class="filled-button">Read More</a>
            </div>
          </div>
          <div class="col-md-6">
            <div class="right-image">
              <img src="assets/images/feature-image.jpg" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="call-to-action">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="inner-content">
              <div class="row">
                <div class="col-md-8">
                  <h4>Creative &amp; Unique <em>Sixteen</em> Products</h4>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Itaque corporis amet elite author nulla.</p>
                </div>
                <div class="col-md-4">
                  <a href="#" class="filled-button">Purchase Now</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
