    <!-- Page Content -->
    <div class="page-heading contact-heading header-text">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="text-content">
              <h4>contact us</h4>
              <h2>let’s get in touch</h2>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="find-us">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="section-heading text-center">
              <h1>Our Location on Maps</h1>
            </div>
          </div>
          <div class="col-md-8">
            <div id="map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3820401.2452112874!2d72.09571887614861!3d20.75644382281808!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9765c0fe803d%3A0xbd72bf8785c6ca52!2sTriad%20Industries!5e0!3m2!1sen!2sin!4v1647718650992!5m2!1sen!2sin"
                width="100%" height="330px" frameborder="0" style="border:0" allowfullscreen></iframe>
            </div>
          </div>
          <div class="col-md-4">
            <div class="left-content">
              <div>
                <h4><i class="fa fa-map-marker" aria-hidden="true"></i>&nbsp;&nbsp;Address</h4>
                <p>H/10, Kalptaru Industrial Estate Moraiya, Sanand, Gujarat-382213</p>
              </div>
              <div>
                <h4><i class="fa fa-phone" aria-hidden="true"></i>&nbsp;&nbsp;Phone</h4>
                <p>+91-9104152901</p>
              </div>
              <div>
                <h4><i class="fa fa-envelope" aria-hidden="true"></i>&nbsp;&nbsp;E-mail</h4>
                <a href="mailto:info@tinybee.toys">
                  <p>info@tinybee.toys</p>
                </a>
              </div>
              <!-- <ul class="social-icons">
                <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                <li><a href="#"><i class="fa fa-linkedin"></i></a></li>
                <li><a href="#"><i class="fa fa-behance"></i></a></li>
              </ul> -->
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-5">
            <div class="section-heading text-center">
              <h1>SEND US A MESSAGE</h1>
            </div>
          </div>
          <div class="col-md-12">
            <div class="contact-form">
              <form [formGroup]="form" class="" (ngSubmit)="onSubmit()" method="post">
                <div class="form-group">
                  <input type="text" class="form-control" formControlName="userName" id="name"
                    aria-describedby="fullName" placeholder="Full Name">
                  <div *ngIf="isSubmitted && f.userName.errors" class="invalid-feedback">
                    <div *ngIf="f.userName.errors.required">Name is required</div>
                  </div>
                </div>
                <div class="form-group">
                  <input type="email" class="form-control" formControlName="email" id="email"
                    placeholder="E-Mail Address">
                  <div *ngIf="isSubmitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">E-Mail address is required</div>
                    <div *ngIf="f.email.errors.email">E-Mail address is not valid</div>
                  </div>
                </div>
                <div class="form-group">
                  <input type="text" class="form-control" formControlName="subject" id="subject" placeholder="Subject">
                  <div *ngIf="isSubmitted && f.subject.errors" class="invalid-feedback">
                    <div *ngIf="f.subject.errors.required">Subject is required</div>
                  </div>
                </div>
                <div class="form-group">
                  <textarea class="form-control" id="message" formControlName="message" rows="5"
                    placeholder="Your Message"></textarea>
                  <div *ngIf="isSubmitted && f.message.errors" class="invalid-feedback">
                    <div *ngIf="f.message.errors.required">Please enter some message</div>
                  </div>
                </div>
                <div class="w-100 text-center">
                  <button type="submit" class="btn btn-danger">Send Us A Message</button>
                </div>
                <!-- <button type="button" class="btn btn-danger" (click)="click()">Testing</button> -->
              </form>
            </div>
          </div>
          <!-- <div class="col-md-4">
            <ul class="accordion">
              <li>
                  <a>Accordion Title One</a>
                  <div class="content">
                      <p>Lorem ipsum dolor sit amet, consectetur adipisic elit. Sed voluptate nihil eumester consectetur similiqu consectetur.<br><br>Lorem ipsum dolor sit amet, consectetur adipisic elit. Et, consequuntur, modi mollitia corporis ipsa voluptate corrupti elite.</p>
                  </div>
              </li>
              <li>
                  <a>Second Title Here</a>
                  <div class="content">
                      <p>Lorem ipsum dolor sit amet, consectetur adipisic elit. Sed voluptate nihil eumester consectetur similiqu consectetur.<br><br>Lorem ipsum dolor sit amet, consectetur adipisic elit. Et, consequuntur, modi mollitia corporis ipsa voluptate corrupti elite.</p>
                  </div>
              </li>
              <li>
                  <a>Accordion Title Three</a>
                  <div class="content">
                      <p>Lorem ipsum dolor sit amet, consectetur adipisic elit. Sed voluptate nihil eumester consectetur similiqu consectetur.<br><br>Lorem ipsum dolor sit amet, consectetur adipisic elit. Et, consequuntur, modi mollitia corporis ipsa voluptate corrupti elite.</p>
                  </div>
              </li>
              <li>
                  <a>Fourth Accordion Title</a>
                  <div class="content">
                      <p>Lorem ipsum dolor sit amet, consectetur adipisic elit. Sed voluptate nihil eumester consectetur similiqu consectetur.<br><br>Lorem ipsum dolor sit amet, consectetur adipisic elit. Et, consequuntur, modi mollitia corporis ipsa voluptate corrupti elite.</p>
                  </div>
              </li>
            </ul>
          </div> -->
        </div>
      </div>
    </div>


    <div class="send-message">
      <div class="container">

      </div>
    </div>
