<div class="page-heading about-heading header-text">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="text-content">
          <h4>about us</h4>
          <h2>our company</h2>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="best-features about-features">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="section-heading">
          <h2>Who we are &amp; What we do?</h2>
        </div>
      </div>
      <!-- <div class="col-md-6">
          <div class="right-image">
            <img src="assets/images/team.jpeg" alt="">
          </div>
        </div> -->
      <div class="col-md-12">
        <div>
          <!-- <h4>Who we are &amp; What we do?</h4> -->
          <div style="border-bottom: 1px solid #eee;">
            <p class="f-17" style="text-align: justify;
            text-justify: inter-word;">TinyBee is a toy manufacturing brand with a mission to create wooden toys which
              empower your
              children to explore the wonder of childhood. These days where parents spend most of the time worrying
              about their children's fondness for electronic gadgets more than toys, TinyBee is nothing less than a
              saviour.<br><br>
              At TinyBee, we firmly believe that toys are ideal companions for kids to grow with. Being enthusiastic
              engineers, we take functionality, learning, entertainment, quality and safety of a child into
              consideration while designing a new toy. With a zeal to get what your little one really needs, we create
              innovative products and experiences that inspire, entertain and develop children through play. The unique
              designs of our products bring out the curiosity and the creativity from within the children.<br><br>
              At TinyBee, toys are manufactured in a state-of-the-art facility, complying with all the toy safety
              standards.</p>
            <!-- <ul class="social-icons">
              <li><a href="#"><i class="fa fa-facebook"></i></a></li>
              <li><a href="#"><i class="fa fa-twitter"></i></a></li>
              <li><a href="#"><i class="fa fa-linkedin"></i></a></li>
              <li><a href="#"><i class="fa fa-behance"></i></a></li>
            </ul> -->
            <div class="quote p-2">
              <span class="h4">“Let’s Play Together, Let’s Learn Together, Let’s Grow Together"</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Team Start -->
<!-- <div class="team-members">
    <div class="container">
      <div class="row" style="border-bottom: 1px solid #eee ;">
        <div class="col-md-12">
          <div class="section-heading">
            <h2>Our Team</h2>
          </div>
        </div>
        <div class="col-md-4">
          <div class="team-member">
            <div class="thumb-container">
              <img src="assets/images/t01.jpeg" alt="Varun Savani">
              <div class="hover-effect">
                <div class="hover-content">
                  <ul class="social-icons">
                    <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                    <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                    <li><a href="https://www.linkedin.com/in/varun-savani-534446128/"><i class="fa fa-linkedin"></i></a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="down-content">
              <h4>Varun Savani</h4>
              <span>CO-Founder</span>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="team-member">
            <div class="thumb-container">
              <img src="assets/images/t02.jpeg" alt="">
              <div class="hover-effect">
                <div class="hover-content">
                  <ul class="social-icons">
                    <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                    <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                    <li><a href="https://www.linkedin.com/in/prashant-rakholiya-659b02159/"><i class="fa fa-linkedin"></i></a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="down-content">
              <h4>Prashant Rakholiya</h4>
              <span>CO-Founder</span>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="team-member">
            <div class="thumb-container">
              <img src="assets/images/t03.jpeg" alt="">
              <div class="hover-effect">
                <div class="hover-content">
                  <ul class="social-icons">
                    <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                    <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                    <li><a href="https://www.linkedin.com/in/harshil-savsani-72aa82211/" target="_blank"><i class="fa fa-linkedin"></i></a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="down-content">
              <h4>Harshil Savsani</h4>
              <span>CO-Founder</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
<!-- Team End -->
<div class="happy-clients">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="section-heading">
          <h2>Certificates</h2>
        </div>
      </div>
      <div class="col-md-12">
        <div class="row">
          <div class="col-sm-2">
            <img class="shadow p-3 mb-5 bg-white rounded" src="../../assets/images/certificate/BIS.svg"
              alt="BIS Certificate">
          </div>
        </div>
        <!-- <div class="owl-clients owl-carousel owl-loaded owl-drag">
          <div class="owl-stage-outer">
            <div class="owl-stage"
              style="transform: translate3d(-710px, 0px, 0px); transition: all 0s ease 0s; width: 2841px;">
              <div class="owl-item cloned" style="width: 216.667px; margin-right: 20px;">
                <div class="client-item"><img src="../assets/images/client-01.png" alt="website template image">
                </div>
              </div>
              <div class="owl-item cloned" style="width: 216.667px; margin-right: 20px;">
                <div class="client-item"><img src="../assets/images/client-01.png" alt="website template image">
                </div>
              </div>
              <div class="owl-item cloned" style="width: 216.667px; margin-right: 20px;">
                <div class="client-item"><img src="../assets/images/client-01.png" alt="website template image">
                </div>
              </div>
              <div class="owl-item active" style="width: 216.667px; margin-right: 20px;">
                <div class="client-item"><img src="../assets/images/client-01.png" alt="website template image">
                </div>
              </div>
              <div class="owl-item active" style="width: 216.667px; margin-right: 20px;">
                <div class="client-item"><img src="../assets/images/client-01.png" alt="website template image">
                </div>
              </div>
              <div class="owl-item active" style="width: 216.667px; margin-right: 20px;">
                <div class="client-item"><img src="../assets/images/client-01.png" alt="website template image"></div>
              </div>
              <div class="owl-item" style="width: 216.667px; margin-right: 20px;">
                <div class="client-item"><img src="../assets/images/client-01.png" alt="website template image"></div>
              </div>
              <div class="owl-item" style="width: 216.667px; margin-right: 20px;">
                <div class="client-item"><img src="../assets/images/client-01.png" alt="website template image"></div>
              </div>
              <div class="owl-item" style="width: 216.667px; margin-right: 20px;">
                <div class="client-item"><img src="../assets/images/client-01.png" alt="website template image"></div>
              </div>
              <div class="owl-item cloned" style="width: 216.667px; margin-right: 20px;">
                <div class="client-item"><img src="../assets/images/client-01.png" alt="website template image"></div>
              </div>
              <div class="owl-item cloned" style="width: 216.667px; margin-right: 20px;">
                <div class="client-item"><img src="../assets/images/client-01.png" alt="website template image"></div>
              </div>
              <div class="owl-item cloned" style="width: 216.667px; margin-right: 20px;">
                <div class="client-item"><img src="../assets/images/client-01.png" alt="website template image"></div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>

  <!-- <div class="services">
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <div class="service-item">
            <div class="icon">
              <i class="fa fa-gear"></i>
            </div>
            <div class="down-content">
              <h4>Product Management</h4>
              <p>Lorem ipsum dolor sit amet, consectetur an adipisicing elit. Itaque, corporis nulla at quia quaerat.</p>
              <a href="#" class="filled-button">Read More</a>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="service-item">
            <div class="icon">
              <i class="fa fa-gear"></i>
            </div>
            <div class="down-content">
              <h4>Customer Relations</h4>
              <p>Lorem ipsum dolor sit amet, consectetur an adipisicing elit. Itaque, corporis nulla at quia quaerat.</p>
              <a href="#" class="filled-button">Details</a>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="service-item">
            <div class="icon">
              <i class="fa fa-gear"></i>
            </div>
            <div class="down-content">
              <h4>Global Collection</h4>
              <p>Lorem ipsum dolor sit amet, consectetur an adipisicing elit. Itaque, corporis nulla at quia quaerat.</p>
              <a href="#" class="filled-button">Read More</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
