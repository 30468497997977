<!-- <footer>
  <div class="container inner-content">
    <div class="row">
      <div class="col-6">
        News Later
        <div>
          <input type="email" class="form-control" id="email" placeholder="E-mail">
          <button type="button" class="btn btn-danger">Subscribe</button>
        </div>
      </div>
      <div class="col-6">
        <div class="h6">
          Follow Us
          <div class="mt-2 h4">
            <i class="fa fa-facebook-square ml-2"></i>
            <i class="fa fa-instagram ml-2"></i>
            <i class="fa fa-twitter ml-2"></i>
            <i class="fa fa-youtube ml-2"></i>
          </div>

        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="inner-content">
        <p>Copyright &copy; 2021 Triad.</p>
        </div>
      </div>
    </div>
  </div>
</footer> -->



<footer class="bg-dark mt-4 text-white">
  <div class="container pt-4">
    <div class="row">
      <div class="col-sm">
        <!-- Section: Social media -->
        <section class="h-100">
          <!-- <div class="row"> -->
          <div class="row d-flex justify-content-center">
            <!--Grid column-->
            <div class="col-auto">
              <p class="pt-2" style="color: #fff;">
                <strong>CONNECT WITH US</strong>
              </p>
            </div>
            <div class="col-auto col-12">
              <!-- Facebook -->
              <a mdbRipple target="_blank" class="btn btn-outline-light btn-floating m-1"
                href="https://www.facebook.com/tinybeetoys/" role="button"><i class="fa fa-facebook-f"></i></a>

              <!-- Twitter -->
              <a mdbRipple target="_blank" class="btn btn-outline-light btn-floating m-1"
                href="https://twitter.com/tinybeetoys?t=nkQ_6qJVb_0XYsSxp5mUQA&s=08" role="button"><i
                  class="fa fa-twitter"></i></a>

              <!-- Google -->
              <!-- <a mdbRipple class="btn btn-outline-light btn-floating m-1" href="#!" role="button"><i
                  class="fa fa-google"></i></a> -->

              <!-- Instagram -->
              <a mdbRipple target="_blank" class="btn btn-outline-light btn-floating m-1"
                href="https://www.instagram.com/invites/contact/?i=tv2s35852q26&utm_content=n6ceenp" role="button"><i
                  class="fa fa-instagram"></i></a>

              <!-- Linkedin -->
              <a mdbRipple target="_blank" class="btn btn-outline-light btn-floating m-1"
                href="https://www.linkedin.com/company/tinybee-toys/" role="button"><i class="fa fa-linkedin"></i></a>

              <!-- Github -->
              <!-- <a mdbRipple class="btn btn-outline-light btn-floating m-1" href="#!" role="button"><i
                  class="fa fa-github"></i></a> -->
            </div>
          </div>
        </section>
        <!-- Section: Social media -->
      </div>
      <div class="col-sm">
        <section>
          <form action="">
            <!--Grid row-->
            <div class="row d-flex justify-content-center">
              <!--Grid column-->
              <div class="col-auto">
                <p class="pt-2" style="color: #fff; margin: 0%;">
                  <strong>SUBSCRIBE TO OUR NEWSLATER</strong>
                </p>
              </div>
              <!--Grid column-->

              <!--Grid column-->
              <div class="col-auto col-12 d-flex justify-content-center">
                <!-- Email input -->
                <form class="form-inline form-white m-2">
                  <div class="form-group">
                    <input type="email" id="newsLaterEmail" class="form-control" placeholder="Email address" />
                  </div>
                  <div class="form-group">
                    <button type="submit" class="btn btn-outline-light ml-2">
                      Subscribe
                    </button>
                  </div>
                </form>

              </div>
              <!--Grid column-->

              <!--Grid column-->
              <!-- <div class="col-auto"> -->
              <!-- Submit button -->
              <!-- <button mdbRipple type="submit" class="btn btn-outline-light mb-4"> -->
              <!-- Subscribe -->
              <!-- </button> -->
              <!-- </div> -->
              <!--Grid column-->
            </div>
            <!--Grid row-->
          </form>
        </section>
      </div>
    </div>
  </div>
  <!-- Copyright -->
  <div class="text-center p-3" style="background-color: rgba(0, 0, 0, 0.2);">
    © 2022 Copyright:
    <a class="text-white" href="https://tinybee.toys/">Tinybee</a>
  </div>
  <!-- Copyright -->
</footer>
<!-- Footer -->
