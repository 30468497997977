    <!-- ***** Preloader Start ***** -->
    <!-- <div id="preloader">
      <div class="jumper">
          <div></div>
          <div></div>
          <div></div>
      </div>
  </div> -->
    <!-- ***** Preloader End ***** -->

    <!-- Header -->
    <!-- <header id="header">
    <nav class="navbar navbar-expand-lg">
      <div class="container">
        <a class="navbar-brand" routerLink="/"><img style="height: 100px; width: 250px;" src="../../assets/images/logo/Tinybee-logo.svg"></a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarResponsive" >
          <ul class="navbar-nav" >
            <li class="nav-item">
              <h3><a class="nav-link" [routerLink]="['/']" [routerLinkActive]="['activeMenu']" >Home</a></h3>
            </li>
            <li class="nav-item">
              <h3><a class="nav-link" [routerLink]="['/products']" [routerLinkActive]="['activeMenu']">Our Products</a></h3>
            </li>
            <li class="nav-item">
              <h3><a class="nav-link" [routerLink]="['/blog']" [routerLinkActive]="['activeMenu']">Blog</a></h3>
            </li>
            <li class="nav-item">
              <h3><a class="nav-link" [routerLink]="['/about-us']" [routerLinkActive]="['activeMenu']">About Us</a></h3>
            </li>
            <li class="nav-item">
              <h3> <a class="nav-link" [routerLink]="['/contact-us']" [routerLinkActive]="['activeMenu']">Contact Us</a></h3>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header> -->

    <!-- <header> -->
    <!-- <div class="m-4"> -->
    <nav class="navbar sticky-top navbar-expand-lg navbar-dark bg-dark">
      <div class="container-fluid">
        <a routerLink="/home" class="navbar-brand">
          <img src="../../assets/images/logo/Tinybee-logo.svg" height="28" alt="Tinybee">
        </a>
        <button type="button" class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
          <div class="navbar-nav ms-auto">
            <a routerLink="/home" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"
              routerLinkActive="active" class="nav-item nav-link">Home</a>
            <a routerLink="/products" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"
              routerLinkActive="active" class="nav-item nav-link">Our
              Products</a>
            <!-- <a routerLink="/blog" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"
              routerLinkActive="active" class="nav-item nav-link">Blog</a> -->
            <a routerLink="/about-us" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"
              routerLinkActive="active" class="nav-item nav-link">About Us</a>
            <a routerLink="/contact-us" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"
              routerLinkActive="active" class="nav-item nav-link">Contact Us</a>
          </div>
          <!-- <div class="navbar-nav ms-auto">
                    <a href="#" class="nav-item nav-link">Login</a>
                </div> -->
        </div>
      </div>
    </nav>
    <!-- </div> -->
    <!-- </header> -->
